import react, { useState } from "react";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  useIonViewWillEnter,
} from "@ionic/react";
import Header from "../components/Header";
import { useHistory } from "react-router";
import "./Basic_style.scss";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Quantity: React.FC = () => {
  const [articleSelectedQuantity, setArticleSelectedQuantity] =
    useState<string>("");
  const [trackingCodeDetail, setTrackingCodeDetail] = useState<any>("");
  const [trackingInputDetails, setTrackingInputDetails] = useState<any>("");
  const [orderItemDetail, setOrderItemDetail] = useState<any>("");

  const history = useHistory();

  useIonViewWillEnter(() => {
    let tracking_input_details = cookies.get("retour_tracking_input_details");
    let tracking_code_detail = cookies.get("retour_tracking_code_detail");
    tracking_code_detail = tracking_code_detail.orders;
    setTrackingInputDetails(tracking_input_details);
    setTrackingCodeDetail(tracking_code_detail);

    tracking_code_detail.map((item: any, index: any) => {
      if (item.articleNumber == tracking_input_details.article_number) {
        setOrderItemDetail(item);
        setArticleSelectedQuantity(item.quantity);
        if(item.quantity == 1){
          processing(item.quantity);
        }
      }
    });
  });

  const processing = (quantity: any) => {
    setArticleSelectedQuantity(quantity.toString());
    let tracking_code_detail = cookies.get('retour_tracking_code_detail');
    let tracking_input_details = cookies.get("retour_tracking_input_details");
    
    tracking_input_details.quantity = quantity.toString();
    setTrackingCodeDetail(tracking_code_detail);
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("retour_tracking_input_details", tracking_input_details, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });
    setArticleSelectedQuantity('');
    history.push("/record-return");
  }

  const handleClick = (quantity: any) => {
    if (quantity && quantity != '' && !isNaN(quantity)) {
      processing(quantity);
    }
  };

  return (
    <IonPage>
      <Header title="Retourenartikel erfassen" />
      <IonContent fullscreen className="wrapper">
        <IonGrid className="ps-15">
          <IonRow className="list-row">
            <IonCol>
              <h3>Artikel EAN:</h3>
              <h4>{trackingInputDetails.article_name}</h4>
              <h4>{trackingInputDetails.article_number}</h4>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid class="grid-section">
          <div className="mb-20">
            <h3>Menge</h3>
          </div>
          <IonRadioGroup
            value={parseInt(articleSelectedQuantity)}
            onIonChange={() => {}}
            onClick={(e:any) => {handleClick(e.target.value)}}
          >
            <IonRow className="ion-radio-row">
              {[
                ...Array(
                  orderItemDetail ? parseInt(orderItemDetail.quantity) : 0
                ),
              ].map((item: any, index: any) => {
                return (
                  <IonCol key={index} className="ion-radio-col" size="4">
                    <IonRadio
                      mode="md"
                      className="custom-radio"
                      value={index + 1}
                    />
                    <IonLabel className="custom-label">{index + 1}</IonLabel>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonRadioGroup>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Quantity;
