import {
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonRadioGroup,
  IonListHeader,
  IonLabel,
  IonRadio,
  IonIcon,
  IonButtons,
  IonButton,
  IonImg,
  IonInput,
  IonAlert,
  useIonViewWillEnter
} from "@ionic/react";
import { arrowBackOutline, checkmarkCircleOutline, arrowForwardOutline } from "ionicons/icons";
import { useState, useRef } from "react";
import { useHistory } from "react-router";
import Header from "../components/Header";
import "./Basic_style.scss";
import "./returnStatus.scss";
import Cookies from "universal-cookie";
import { BASE_URL } from "../BaseUrl";
import axios from "axios";
import NewReturn from "./newReturn";
import NewOrderArticle from "./newOrderArticle";
const cookies = new Cookies();

const ReturnStatus: React.FC = () => {
  const history = useHistory();
  const [articleNumber, setArticleNumber] = useState<any>('');
  const [trackingInputDetail, setTrackingInputDetail] = useState<any>('');

  useIonViewWillEnter(() => {
    let tracking_input_detail = cookies.get('retour_tracking_input_details');
    setArticleNumber(tracking_input_detail.article_number);
    setTrackingInputDetail(tracking_input_detail);
  });

  const orderClick = () => {
    history.push("/order-article");
  };
  const homeClick = () => {
    history.push("/record-return-article");
  };

  return (
    <IonPage>
      <Header title="Retourenartikel erfassen" />
      <IonContent fullscreen className="wrapper">
        <IonGrid className="ps-15">
          <IonRow className="list-row">
            <IonCol>
              <h3>Artikel EAN:</h3>
              <h4>{trackingInputDetail.article_name}</h4>
              <h4>{articleNumber}</h4>
              <br/>
              <h3>Menge:</h3>
              <h4>{trackingInputDetail.quantity}</h4>
              <br/>
              <h3>Grund:</h3>
              <h4>{trackingInputDetail.reason}</h4>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="status-check">
          <div className="status-message">
            <IonImg
              src={require("../assets/images/checkmark-circle-outline.png")}
            />
            <p>Retoure für Artikel erfolgreich erfasst.</p>
          </div>
          <div className="status-button">
            {/* <NewReturn/> */}
            <NewOrderArticle />
            {/* <IonButton onClick={orderClick} className="confirm-button mb-15">
              Nächster Artikel
            </IonButton> */}
            <IonButton onClick={homeClick} className="confirm-button">
              Neue Retoure
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ReturnStatus;
