import { IonHeader, IonImg, IonToolbar } from '@ionic/react';
import React from 'react' 
import { useHistory } from 'react-router';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Header = (props:any) => {
  const Image= require('../assets/images/close.png');
  const history=useHistory();
  const closeClick=()=>{
    cookies.remove("retour_tracking_codes", {
      path: "/",
    });
    history.push("/home");
  }
  return (
    <IonHeader>
    <IonToolbar>
      <div className="toolbar-title">
        <p>{props.title}</p>
        <IonImg onClick={closeClick} src={Image}></IonImg>
        </div>
    </IonToolbar>
  </IonHeader>
  )
}

export default Header;
