import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* import pages*/
import Home from './pages/Home';
import RecordIncoming from './pages/recordIncoming';
import RecordList from './pages/recordList';
import RecordReturn from './pages/RecordReturn';
import RecordReturnArticle from './pages/recordReturnArticles';
import Quantity from './pages/Quantity';
import OrderArticle from './pages/orderArticle';
import ReturnStatus from './pages/returnStatus';
setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/record-incoming">
          <RecordIncoming/>
        </Route>
        <Route exact path="/record-list">
          <RecordList/>
        </Route>
        <Route exact path="/record-return">
          <RecordReturn/>
        </Route>
        <Route exact path="/record-return-article">
          <RecordReturnArticle/>
        </Route>
        <Route exact path="/order-article">
          <OrderArticle/>
        </Route>
        <Route exact path="/return-status">
          <ReturnStatus/>
        </Route>
        <Route exact path="/quantity">
          <Quantity/>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
