import { IonToast } from '@ionic/react';

type toastData = {
    message: string
    showToast: boolean
    toastSetting: any
}

const InfoToast: React.FC<toastData> = (props) => {
  return (
    <IonToast
        isOpen={props.showToast}
        onDidDismiss={() => props.toastSetting(false)}
        message={props.message}
        duration={700}
        cssClass="info-toast"
    />
  );
};

export default InfoToast;