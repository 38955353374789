import {
  IonContent,
  IonIcon,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonBackButton,
  IonSpinner,
  IonAlert,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  arrowForwardOutline,
  arrowBackOutline,
  closeOutline,
  code,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Header from "../components/Header";
import "./Basic_style.scss";
import "./recordList.scss";
import Cookies from "universal-cookie";
import Toast from "../components/InfoToast";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const cookies = new Cookies();

const RecordList: React.FC = () => {
  const inputRef = useRef<any>(null);

  const alertImage = require("../assets/images/checkmark-circle-outline.png");
  const alertImage2 = require("../assets/images/close-circle-outline.png");
  const [showAlert4, setShowAlert4] = useState<any>(false);
  const [showAlert5, setShowAlert5] = useState<any>(false);
  const [trackingCode, setTrackingCode] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [trackingCodes, setTrackingCodes] = useState<any>([]);
  const [removalIndex, setRemovalIndex] = useState<any>("");
  const [totalEntries, setTotalEntries] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>("");
  const [alertButtons, setAlertButtons] = useState<any>([]);

  const history = useHistory();

  useEffect(() => {}, [totalEntries]);

  useIonViewWillEnter(() => {
    setTimeout(() => inputRef.current.setFocus(), 100);
    let codes = cookies.get("retour_tracking_codes")
      ? cookies.get("retour_tracking_codes")
      : [];
    setLoading(false);
    setTrackingCodes(codes);
    setTotalEntries(codes.length);
  });

  const playBeep = (type:any) => {
    let beep = type === 'scan'? new Audio(require("../assets/beeps/scan-beep.mp3")) : new Audio(require("../assets/beeps/error-beep.mp3"));
    beep.play();
  }

  const infoAlert = (message: any, type: any) => {
    setAlertMessage(message);
    if(type == 'info alert'){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
            setTimeout(() => inputRef.current.setFocus(), 100);
          },
        },
      ]);
    }else if(type == "success alert"){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
            history.push("/home");
          },
        },
      ]);
    }else if(type == "reset alert"){
      setAlertButtons([
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Löschen",
          cssClass: "secondary",
          handler: () => {
            handleRemoveAllTrackingCodes();
          },
        },
      ]);
    }else if(type == "remove alert"){
      setAlertButtons([
        {
          text: "Abbrechen",
          cssClass: "secondary",
        },
        {
          text: "Löschen",
          cssClass: "secondary",
          handler: () => {
            handleRemoveTrackingCodeEntry();
          },
        },
      ]);
    }
    setShowAlert(true);
  }

  const trackingCodeAddition = async () => {
    if (trackingCode == "" || trackingCode == " ") {
      // setMessage("Invalid tracking code");
      // setShowToast(true);
      playBeep('error');
      infoAlert("Invalid tracking code", "info alert");
      setTrackingCode("");
    }else if(!/^[0-9]+$/.test(trackingCode) || trackingCode.length < 12) {
      playBeep('error');
      infoAlert("Format Tracking ID is not correct.", "info alert");
      setTrackingCode("");
    } else {
      let tracking_codes = cookies.get("retour_tracking_codes")
        ? cookies.get("retour_tracking_codes")
        : [];

      for await (let tracking of tracking_codes) {
        if (tracking == trackingCode) {
          // setMessage("Tracking code already exists");
          // setShowToast(true);
          playBeep('error');
          infoAlert("Tracking code already exists", "info alert");
          setTrackingCode("");
          return;
        }
      }
      tracking_codes.push(trackingCode);
      setTrackingCodes(tracking_codes);
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      cookies.set("retour_tracking_codes", tracking_codes, {
        path: "/",
        expires: date,
        sameSite: "lax",
      });
      setTotalEntries(tracking_codes.length);
      setTrackingCode("");
      playBeep('scan');
    }
  }

  const handleEnter = async (e: any) => {
    if (e.charCode == "13") {
      trackingCodeAddition();
    }
  };

  const handleClick = async () => {
    trackingCodeAddition();
  };

  const toastSetting = (setting: any) => {
    setShowToast(setting);
  };

  const handleRemoveAllTrackingCodes = () => {
    setTrackingCodes([]);
    cookies.remove("retour_tracking_codes", {
      path: "/",
    });
    setTotalEntries("0");
  };

  const handleRemoveTrackingCodeEntry = () => {
    let codes = cookies.get("retour_tracking_codes");
    codes.splice(removalIndex, 1);
    setTrackingCodes(codes);
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("retour_tracking_codes", codes, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });
    setTotalEntries(codes.length);
  };

  const handleSaveTrackingIds = () => {
    if (trackingCodes.length > 0 && !loading) {
      setLoading(true);
      let url = BASE_URL + "retouretrackingids";
      axios
        .post(url, {
          retouretrackingids: trackingCodes,
        })
        .then((res) => {
          setLoading(false);
          if (res.status == 201) {
            infoAlert(`<img src = "${alertImage}"/>
            <p>Einträge erfolgreich gesendet.</p>`, "success alert");
            // setShowAlert3(true);
            setTrackingCodes([]);
            let date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            cookies.set("retour_tracking_codes", [], {
              path: "/",
              expires: date,
              sameSite: "lax",
            });
          } else {
            infoAlert("Something went wrong", "info alert");
            // setMessage("Something went wrong");
            // setShowToast(true);
          }
        })
        .catch((err) => {
          console.log(err);
          infoAlert("Something went wrong", "info alert");
          // setMessage("Something went wrong");
          // setShowToast(true);
        });
    } else if (trackingCodes.length > 0 && loading) {
      infoAlert("Please wait...", "info alert");
      // setMessage("Please wait...");
      // setShowToast(true);
    } else if (trackingCodes.length <= 0) {
      infoAlert("Please add at least one tracking ID", "info alert");
      // setMessage("Please add at least one tracking ID");
      // setShowToast(true);
    }
  };

  return (
    <IonPage>
      <Header title="Retoureneingang erfassen" />
      <IonContent fullscreen className="wrapper">
        <div className="product-detailList">
          <IonGrid className="list-grid">
            <IonRow className="list-row">
              <IonCol className="p-0">
                <h3>Anzahl: {totalEntries}</h3>
              </IonCol>
              <IonCol className="button-column">
                <IonButton
                  onClick={() => {
                    infoAlert("Soll die komplette Liste gelöscht werden?", "reset alert");
                    //setShowAlert1(true)
                  }}
                  className="reset-button"
                >
                  Reset Liste
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="list-data">
            {trackingCodes.map((item: any, index: any) => {
              let serial = index + 1;
              return (
                <div key={index} className="list-detail">
                  <div className="num-id">
                    <h5>{serial}:</h5>
                    <h5>{item}</h5>
                  </div>
                  <div>
                    <IonImg
                      src={require("../assets/images/close2.png")}
                      onClick={() => {
                        setRemovalIndex(index);
                        infoAlert(`Soll die ID ${index + 1} gelöscht werden?`, "remove alert");
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="record-input">
            <IonItem lines="none" className="record-items">
              <IonInput
                onIonChange={(e) => setTrackingCode(e.detail.value)}
                onKeyPress={(event) => handleEnter(event)}
                ref={(ref) => (inputRef.current = ref)}
                value={trackingCode}
                className="input_focus"
                placeholder="ID manuell eingeben"
              />
              <IonIcon
                className="arrow-forward-icon"
                icon={arrowForwardOutline}
                onClick={handleClick}
              />
            </IonItem>
            <IonButton
              onClick={() => handleSaveTrackingIds()}
              className="confirm-button"
            >
              {loading ? (
                <IonSpinner className="load-spinner" name="crescent" />
              ) : (
                "Bestätigen & Senden"
              )}
            </IonButton>
          </div>
        </div>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={alertMessage}
          buttons={alertButtons}
        />

        {/* Unused alerts */}
        <IonAlert
          isOpen={showAlert5}
          onDidDismiss={() => setShowAlert5(false)}
          cssClass="my-custom-class"
          message={`<img src = "${alertImage2}"/>
            <h6>Fehler bei der Übertragung.</h6>`}
          buttons={[
            {
              text: "Abbrechen",
              role: "cancel",
              cssClass: "secondary",
            },
            {
              text: "Wiederholen",
              cssClass: "secondary",
            },
          ]}
        />
        <IonAlert
          isOpen={showAlert4}
          onDidDismiss={() => setShowAlert4(false)}
          cssClass="my-custom-class"
          message={`<img src = "${alertImage}"/>
            <p>00344587106578491820 wurde bereits erfasst.</p>`}
          buttons={[
            {
              text: "ok",
            },
          ]}
        />
        {/* end unused alerts */}

        <Toast
          message={message}
          showToast={showToast}
          toastSetting={(setting: any) => toastSetting(setting)}
        />
      </IonContent>
    </IonPage>
  );
};

export default RecordList;
