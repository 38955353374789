import {
  IonContent,
  IonButtons,
  IonIcon,
  IonHeader,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonAlert,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowForwardOutline, arrowBackOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Header from "../components/Header";
import "./Basic_style.scss";
import Toast from "../components/InfoToast";
import Cookies from "universal-cookie";
import { BASE_URL } from "../BaseUrl";
import axios from "axios";
import { trackingCodeDetails } from "../FakeResponse";

const cookies = new Cookies();

const RecordReturnArticle: React.FC = () => {
  const inputRef = useRef<any>(null);

  const [trackingCode, setTrackingCode] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [showToast, setShowToast] = useState<any>(false);
  const [showAlert, setShowAlert] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');
  const [alertButtons, setAlertButtons] = useState<any>([]);

  useIonViewWillEnter(() => {
    setTrackingCode("");
    setTimeout(() => inputRef.current.setFocus(), 100);
  });

  useEffect(() => {
    //setTimeout(() => inputRef.current.setFocus());
  });
  const history = useHistory();

  const fetchTrackingCodeDetails = () => {
    let url = BASE_URL + "retouretrackingid";
    axios
      .post(url,
        {
          retouretrackingid: trackingCode
        },)
      .then((res) => {
        if (res.status == 200 && res.data.orders) {
          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          cookies.set("retour_tracking_code_detail", res.data, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          let tracking_input_detail = {
            tracking_code: trackingCode,
            article_number: "",
            article_name: "",
            quantity: "",
            reason: "",
          };
          cookies.set("retour_tracking_input_details", tracking_input_detail, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("retour_tracking_id", trackingCode, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          cookies.set("retour_order_number", res.data.orders[0].orderNumber, {
            path: "/",
            expires: date,
            sameSite: "lax",
          });
          history.push('/order-article');
        }else if(res.status == 200 && !res.data.orders){
          infoAlert("Nothing found", "info alert");
          // setMessage('Nothing found');
          // setShowToast(true);
        }
      })
      .catch((err) => {
        console.log(err);
        infoAlert("Something went wrong", "info alert");
        // setMessage('Something went wrong');
        // setShowToast(true);
      });
  };

  const fetchFakeTrackingCodeDetails = () => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    cookies.set("retour_tracking_code_detail", trackingCodeDetails, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });

    let tracking_input_detail = {
      tracking_code: trackingCode,
      article_number: "",
      quantity: "",
      reason: "",
    };
    cookies.set("retour_tracking_input_details", tracking_input_detail, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });
    cookies.set("retour_tracking_id", trackingCode, {
      path: "/",
      expires: date,
      sameSite: "lax",
    });
    history.push('/order-article');
  };

  const playBeep = (type:any) => {
    let beep = type === 'scan'? new Audio(require("../assets/beeps/scan-beep.mp3")) : new Audio(require("../assets/beeps/error-beep.mp3"));
    beep.play();
  }

  const infoAlert = (message: any, type: any) => {
    setAlertMessage(message);
    if(type == 'info alert'){
      setAlertButtons([
        {
          text: "ok",
          role: "cancel",
          handler: () => {
            setTimeout(() => inputRef.current.setFocus(), 100);
          },
        },
      ]);
    }
    setShowAlert(true);
  }

  const trackingCodeValidation = () => {
    if (trackingCode == "" || trackingCode == " ") {
      playBeep("error");
      infoAlert("Invalid tracking code", "info alert");
      setTrackingCode("");
      // setMessage("Invalid tracking code");
      // setShowToast(true);
    } else if(!/^[0-9]+$/.test(trackingCode) || trackingCode.length < 12) {
      playBeep("error");
      infoAlert("Format Tracking ID is not correct.", "info alert");
      setTrackingCode("");
    } else {
      playBeep("scan");
      fetchTrackingCodeDetails();
      //fetchFakeTrackingCodeDetails();
    }
  }

  const handleEnter = async (e: any) => {
    if (e.charCode == "13") {
      trackingCodeValidation();
    }
  };

  const handleClick = async () => {
    trackingCodeValidation();
  };

  const toastSetting = (setting: any) => {
    setShowToast(setting);
  };

  return (
    <IonPage>
      <Header title="Retourenartikel erfassen" />
      <IonContent fullscreen className="wrapper">
        <div className="heading-portion">
          <h3>Bitte Tracking Code/ID scannen um den Auftrag zu öffnen.</h3>
        </div>
        <div className="detail_with_image">
          <IonImg src={require("../assets/images/detail.jpg")} />
        </div>
        <div className="id-input-div">
          <IonItem lines="none" className="record-items">
            {/* <IonInput
              ref={(ref) => (inputRef.current = ref)}
              placeholder="ID manuell eingeben"
            /> */}
            <IonInput
              onIonChange={(e) => setTrackingCode(e.detail.value)}
              onKeyPress={(event) => handleEnter(event)}
              ref={(ref) => (inputRef.current = ref)}
              value={trackingCode}
              className="input_focus"
              placeholder="ID manuell eingeben"
            />
            <IonIcon
              onClick={handleClick}
              className="arrow-forward-icon"
              icon={arrowForwardOutline}
            />
          </IonItem>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          message={alertMessage}
          buttons={alertButtons}
        />
        <Toast
          message={message}
          showToast={showToast}
          toastSetting={(setting: any) => toastSetting(setting)}
        />
      </IonContent>
    </IonPage>
  );
};

export default RecordReturnArticle;
